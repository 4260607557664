<template>
    <div>
        <SearchForm @onReset="resetData" @onSearch="filterSearch" />
        <div class="shadow-lg rounded-lg pt-3 pb-5 bg-white mt-10 relative">
            <LoadingComponent :isLoading="loading" />
            <div class="overflow-x-auto">
                <table class="myTable">
                    <thead>
                        <tr>
                            <th>代號</th>
                            <th>名稱</th>
                            <th>期間</th>
                            <th>獎勵</th>
                            <th>狀態</th>
                            <th v-permission="['update']">編輯</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in datas" :key="index" class="text-center duration-200 transition">
                            <td>{{ item.code }}</td>
                            <td>{{ $subString(item.name, 10) }}</td>
                            <td>{{ item.started_at | formatDate }}-{{ item.ended_at | formatDate }}</td>
                            <td class="text-left">
                                <ul>
                                    <li v-for="(reward, rewardIndex) in item.rewards" v-show="reward.type === 'voucher'" :key="rewardIndex">
                                        {{ CampaignConfig.type[reward.type] }}&nbsp;${{ reward.amount | formatCurrency }}
                                    </li>
                                </ul>
                            </td>
                            <td>
                                {{ CampaignConfig.status[item.status] }}
                            </td>
                            <td v-permission="['update']" class="cursor-pointer" @click="goToEdit(item.id)">
                                <i class="far fa-edit"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="justify-center mt-10 flex">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange" />
            </div>
        </div>
    </div>
</template>

<script>
// 導入搜尋條件
import SearchForm from "./SearchForm.vue";
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// 設定檔
import CampaignConfig from "@/config/campaignConfig";
export default {
    components: {
        SearchForm,
        MyPagination,
        LoadingComponent,
    },
    data() {
        return {
            // 預設頁面
            currentPage: 1,
            // 分頁資料
            paginationData: {
                limit: 15,
                total: 0,
            },
            // 搜尋條件
            filterData: {},
            // 資料
            datas: [],
            // 旋轉動畫
            loading: false,
            CampaignConfig,
        };
    },
    methods: {
        /**
         * 搜尋
         * @param { tpye Object(物件) } filterData 搜尋過濾資料
         */
        filterSearch(filterData) {
            this.filterData = filterData;
            this.getLists({
                ...this.filterData,
                page: 1,
                limit: this.paginationData.limit,
            });
        },
        // 清空選擇條件
        resetData() {
            this.currentPage = 1;
            this.isHighestRateSorted = null;
            this.filterData = {};
            this.getLists({
                limit: this.paginationData.limit,
                page: 1,
                ...this.filterData,
            });
        },
        /**
         * 取得列表資料
         * @param { type Object(物件) } filterData 搜尋條件
         */
        async getLists(filterData) {
            this.loading = true;
            // 判斷沒有傳入 sort_type 值時 預設為倒序
            if (!Object.prototype.hasOwnProperty.call(filterData, "sort_type")) {
                filterData["sort_type"] = "desc";
            }
            try {
                const { data } = await this.$api.GetCampaignsListApi(filterData);
                this.loading = false;
                this.currentPage = data.current_page;
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
                this.datas = data.data;
            } catch (err) {
                this.loading = false;
                this.$message({
                    type: "error",
                    message: "取得資料失敗",
                });
            }
        },
        /**
         * 更新一頁取得幾筆資料
         * @param { type Number(數字) } val 一頁取得幾筆資料設定值
         */
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            // 清空列表資料
            this.datas = [];
            this.getLists({
                page: this.currentPage,
                limit: this.paginationData.limit,
                ...this.filterData,
            });
        },
        /**
         * 切換分頁
         * @param { type Number(數字) } val 分頁頁碼
         */
        currentPageChange(val) {
            // 設定目前分頁為第幾幾面
            this.currentPage = val;
            // 清空列表資料
            this.datas = [];
            this.getLists({
                page: this.currentPage,
                limit: this.paginationData.limit,
                ...this.filterData,
            });
        },
        /**
         * 跳轉編輯頁面
         * @param { type Number(數字) } campaignID 活動id
         */
        goToEdit(campaignID) {
            this.$router.push({
                name: "campaign-update",
                params: { campaignID },
            });
        },
    },
    created() {
        this.getLists({ limit: this.paginationData.limit });
    },
};
</script>
