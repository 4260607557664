<template>
    <div class="shadow-lg rounded-lg bg-white px-5 pt-3 pb-5">
        <el-form ref="form" :rules="rules" :model="form">
            <div class="flex items-end">
                <div class="flex-grow">
                    <div class="grid grid-cols-12 gap-4 w-full">
                        <div class="col-span-4">
                            <el-form-item class="mb-0" prop="code">
                                <label class="text-gray-400">
                                    代號
                                    <el-input v-model="form.code" clearable class="w-full"> </el-input>
                                </label>
                            </el-form-item>
                        </div>
                        <div class="col-span-4">
                            <el-form-item class="mb-0" prop="name">
                                <label class="text-gray-400">
                                    名稱
                                    <el-input v-model.number="form.name" clearable class="w-full"> </el-input>
                                </label>
                            </el-form-item>
                        </div>
                        <div class="col-span-4">
                            <el-form-item class="mb-0" prop="status">
                                <label class="text-gray-400">
                                    狀態
                                    <el-select v-model="form.status" class="w-full" clearable>
                                        <el-option v-for="(status, index) in statusOptions" :key="index" :label="status.label" :value="status.value">
                                            {{ status.label }}
                                        </el-option>
                                    </el-select>
                                </label>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-0 ml-4 min-w-[150px]">
                    <btn
                        size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                        customClass="flex-shrink mr-2"
                        @onClick="onSubmit('form')">
                        搜尋
                    </btn>
                    <btn
                        color="border border-black"
                        size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                        customClass="flex-shrink"
                        @onClick="resetData">
                        重置
                    </btn>
                </div>
                <div class="flex-grow-0 ml-4">
                    <div class="mt-5">
                        <btn
                            size="md:text-sm text-xs px-5 py-1 rounded min-w-[150px] max-w-[250px]"
                            color="border border-red-500 text-orange-600"
                            @onClick="$router.push({ name: 'campaign-create' })">
                            <i class="el-icon-plus text-lg"></i>
                            新增活動
                        </btn>
                    </div>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
// 判斷是否有選擇值
import { checkHaveSelectData } from "@/service/anyService";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";

export default {
    components: {
        Btn,
    },
    data() {
        return {
            form: {},
            rules: {},
            // 搜尋狀態
            statusOptions: [
                { label: "進行中", value: 0 },
                { label: "已截止", value: -1 },
            ],
            // 搜尋條件
            filterData: {},
        };
    },
    methods: {
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                this.filterSearch();
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        // 搜尋條件
        async filterSearch() {
            // 判斷是否有值 有值就帶入搜尋條件
            checkHaveSelectData([this.form.code, this.form.name, this.form.status], ["code", "name", "status"], this.filterData);
            this.$emit("onSearch", this.filterData);
        },
        // 清空表單資料
        resetData() {
            this.form = {};
            this.$refs.form.resetFields();
            this.$emit("onReset", {});
        },
    },
};
</script>
